@import 'styles/utils/sizes';
@import 'styles/utils/mixins';

.dropDownMenu {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  padding: 16px 0 64px;
  background-color: $dark-grey;
  color: $white;
  z-index: -1;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.2s, opacity 0.3s ease;
  box-shadow: none;

  .dropDownItemsContainer {
    display: flex;
    justify-content: center;
    gap: 80px;
    max-width: max-content;
    margin: 0 auto 48px;

    .subMenuItem {
      position: relative;
      color: $gray400;
      cursor: pointer;

      &::before {
        content: ' ';
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 80%;
        height: 3px;
        border-radius: 2px;
        background-color: $yellow500;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 0.1s ease-out;

        @include phone {
          display: none;
        }
      }

      @include phone {
        color: $gray0;
        font-weight: 700;
      }
    }

    .subMenuItemActive {
      color: $gray0;
      font-weight: 700;

      &::before {
        opacity: 1;
        transition: opacity 0.2s ease-out;
      }
    }

    @include phone {
      justify-content: flex-start;
      flex-direction: column;
      gap: 0;
    }
  }

  &.pageScrolling {
    .subMenuItemActive {
      color: $gray900;
    }
  }

  .dropDownLinksContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px 65px;
    margin: 0 auto;
    padding: 0 40px;
    gap: 32px;
    max-width: $wide-content-width;

    @include below-tablet {
      display: block;
      margin: 0;
    }
  }

  @include below-tablet {
    position: relative;
    top: 0;
    padding: 0;
    margin-top: 20px;
    width: unset;
    z-index: unset;
  }

  @include phone {
    position: relative;
    top: 0;
    padding: 0;
    margin-top: 20px;
    width: unset;
    z-index: unset;
    background-color: $dark-grey;
  }
}

.pageScrolling {
  background-color: $white;
  box-shadow: 0 40px 60px -40px rgb(0 0 0 / 10%);
  border-bottom: none;
}

.closed {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.4s, opacity 0.5s ease;
}

.lightTheme {
  background-color: $white;
}
